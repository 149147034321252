export const INIT_COURSE_ADMIN_FORM_DATA = {
  title: "",
  price: "",
  discountPrice: "",
  picture: "",
  willLearn: [],
  willGet: [],
  isPublished: false,
  category: "",
  ids: [],
};

export const INIT_VIDEO_ADMIN_FORM_DATA = {
  title: "",
  description: "",
  courseId: "",
  picture: "",
  source: {
    createdAt: 0,
    fileName: "",
    fileSize: 0,
    key: "",
    extension: "",
  },
  isFree: false,
  price: null,
  discountPrice: null,
};

export const INIT_VIDEO_ADMIN_DATA = {
  id: "",
  title: "",
  description: "",
  picture: "",
  source: {
    createdAt: 0,
    fileName: "",
    fileSize: 0,
    key: "",
    extension: "",
  },
};

export const INIT_COURSE_ADMIN_DATA = {
  id: "",
  title: "New course",
  price: null,
  discountPrice: null,
  picture: "",
  isPublished: false,
  category: "",
  videos: [],
  createdAt: "",
  updatedAt: "",
};
