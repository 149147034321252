import React from "react";

// images
import arrow_image from "../../../../assets/images/arrow_middle_background.png";

// hooks
import { useLearningBlock } from "./use-learning-block";

// components
import { LearningCard } from "../learningCard/LearningCard";

// styles
import styles from "./LearningBlock.module.scss";

export function LearningBlock() {
  const { learningCards } = useLearningBlock();

  return (
    <section className={styles.learningSection}>
      <div className={styles.contentWrapper}>
        <div className={styles.learningMain}>
          <img
            src={arrow_image}
            alt="Arrow"
            className={styles.backgroundImage}
          />

          <div className={styles.learningCardsWrapper}>
            <div className={styles.descriptionBlock}>
              <h3 className={styles.learningTitle}>
                Learn the Game of Poker: Essential Tips
              </h3>

              <p className={styles.learningDescription}>
                Start mastering the game with our courses. Whether you're a
                beginner or experienced, learn how to play poker and explore
                various poker types. Our comprehensive plan ensures you become
                skilled and dominant at the table.
              </p>
            </div>

            {learningCards?.map((card, index) => (
              <LearningCard learningCard={card} key={index} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
