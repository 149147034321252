// images
import vikki_reviewer from "../../../../assets/images/vikki_reviewer.png";
import terry_reviewer from "../../../../assets/images/terry_reviewer.png";
import old_terry_reviewer from "../../../../assets/images/old_terry_reviewer.png";

export const reviwersData = [
  {
    avatar_src: vikki_reviewer,
    name: "Vikki",
    surname: "Star",
    rating: 4,
    reviewText:
      "I enrolled in the poker and logical thinking course with high expectations, and I must say, it exceeded them! The lessons were incredibly insightful, breaking down the nuances of poker strategy while honing logical thinking skills. ",
    date: "June 15, 2024",
  },
  {
    avatar_src: terry_reviewer,
    name: "Terry",
    surname: "Baskey",
    rating: 5,
    reviewText:
      "As a poker enthusiast, I found this course to be a game-changer. The logical thinking exercises were not only relevant to poker but also applicable in everyday decision-making. ",
    date: "June 5, 2024",
  },
  {
    avatar_src: old_terry_reviewer,
    name: "Terry",
    surname: "Baskey",
    rating: 5,
    reviewText:
      "This course strikes the perfect balance between theory and practice. The logical thinking principles seamlessly blend with poker strategy, making it a must for anyone serious about improving their game.",
    date: "July 17, 2024",
  },
];
