import React, { useState } from "react";
import classnames from "classnames";
import { generatePath, useNavigate } from "react-router-dom";

// hooks
import { useBlogAdminForm } from "../../../admin/blog-admin-screen/use-blog-admin";
import { useWindowSize } from "../../../../hooks/use-window-size/use-window-size";

// components
import { ConfirmModal } from "../../../../components/confirm-modal/ConfirmModal";
import Button from "../../../../components/button/Button";

// icons
import { EditIcon } from "../../../../assets/icons/EditIcon";
import { DeleteIcon } from "../../../../assets/icons/DeleteIcon";

// consts
import { PATHS } from "../../../../route/route.controls";

// types
import type { BlogType } from "../../../../context/blog-provider/BlogProvider.types";

// styles
import styles from "./BlogListItem.module.scss";

type BlogListItemProps = {
  type?: "admin" | "user";
  blog: BlogType;
  handleOpenEditingModal?: (blog: BlogType) => void;
};

export function BlogListItem({
  type = "user",
  blog,
  handleOpenEditingModal,
}: BlogListItemProps) {
  const { handleRemoveBlog } = useBlogAdminForm(null, null);

  const { width } = useWindowSize();

  const navigate = useNavigate();

  const isUser = type === "user";
  const isAdmin = type === "admin";

  const [isOpenRemovingBlogModal, setIsOpenRemovingBlogModal] = useState(false);

  const generatedLink = generatePath(PATHS.currentBlog, {
    blogId: blog.id,
  });

  return (
    <div
      className={classnames(
        styles.container,
        isUser ? styles.userBlogContainer : ""
      )}
      onClick={isUser ? () => navigate(generatedLink) : undefined}
    >
      <div className={styles.mainInfoWrapper}>
        <img src={blog.picture} alt="ace" />
        <p className={styles.title}>{blog.title}</p>
      </div>

      {isAdmin && handleOpenEditingModal ? (
        width > 768 ? (
          <div className={styles.iconsWrapper}>
            <div className={styles.iconButton}>
              <EditIcon onClick={() => handleOpenEditingModal(blog)} />
            </div>
            <div className={styles.iconButton}>
              <DeleteIcon onClick={() => setIsOpenRemovingBlogModal(true)} />
            </div>
          </div>
        ) : (
          <div className={styles.buttonsWrapper}>
            <Button
              onClick={
                handleOpenEditingModal
                  ? () => handleOpenEditingModal(blog)
                  : undefined
              }
            >
              Edit
            </Button>
            <Button
              onClick={() => setIsOpenRemovingBlogModal(true)}
              variant="outline"
            >
              Delete
            </Button>
          </div>
        )
      ) : null}

      <ConfirmModal
        isOpen={isOpenRemovingBlogModal}
        modalTitle="Delete article"
        modalText={"Are you sure to delete this article?"}
        onClose={() => setIsOpenRemovingBlogModal(false)}
        onSubmit={() => handleRemoveBlog(blog.id)}
      />
    </div>
  );
}
