import React, { FunctionComponent, useContext, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

// contexts
import { apiContext } from "../api-provider/ApiProvider";
import { sessionContext } from "../session-provider/SessionProvider";
import { currencyContext } from "../currency-provider/CurrencyProvider";

// consts
import {
  API_URL_AUTH,
  API_URL_FEEDBACK,
  API_URL_PROFILE,
  API_URL_RESET_PASSWORD,
  API_URL_USER,
  API_URL_USER_NEW_PASSWORD,
} from "./UserProvider.consts";
import { PATHS } from "../../route/route.controls";
import { CART } from "../cart-provider/CartProvider.consts";

// schemas
import { allUsersSchema, profileSchema } from "./UserProvider.schemas";
import { loginSchema } from "../course-provider/CourseProvider.schemas";

// helpers
import { handleErrorWithMessages } from "../error/error";

// types
import type {
  ChangePasswordFormType,
  FeedbackFormType,
  ProfileType,
  ResetPasswordFormType,
  SignUpUserType,
  UpdatedProfileFormType,
  UserContext,
  UserProviderProps,
} from "./UserProvider.types";

export const userContext = React.createContext({} as UserContext);

export const UserProvider: FunctionComponent<UserProviderProps> = (props) => {
  const { api } = useContext(apiContext);
  const { getCurrency } = useContext(currencyContext);
  const { setSessionCookie, setRefreshSessionCookie, removeSessionCookie } =
    useContext(sessionContext);

  const navigate = useNavigate();

  const { children } = props;

  const [users, setUsers] = useState<ProfileType[] | null>(null);
  const [userData, setUserData] = useState<ProfileType | null>(null);

  const signUp = async (signUpUser: SignUpUserType) => {
    try {
      await api(API_URL_AUTH, {
        method: "POST",
        data: { ...signUpUser },
      });
    } catch (error) {
      throw error;
    }
  };

  const logIn = async (
    loginUser: Pick<SignUpUserType, "email" | "password">
  ) => {
    try {
      const authData = await api(
        API_URL_AUTH,
        {
          method: "PUT",
          data: { email: loginUser.email, password: loginUser.password },
        },
        loginSchema
      );

      if (authData) {
        setSessionCookie(authData.accessToken, authData.accessTokenExpiresIn);
        setRefreshSessionCookie(
          authData.refreshToken,
          authData.refreshTokenExpiresIn
        );

        await getProfile();
      }
    } catch (error) {
      throw handleErrorWithMessages(error, {
        "UserNotFoundException: User not found":
          "Invalid credentials. Please try again.",
        "UnauthorizedException: Invalid credentials":
          "Invalid credentials. Please try again.",
      });
    }
  };

  const logOut = async () => {
    try {
      await api(API_URL_AUTH, { method: "DELETE" });

      removeSessionCookie();

      const updatedCart = { courses: [], membershipId: "", videos: [] };

      localStorage.setItem(CART, JSON.stringify(updatedCart));

      navigate(PATHS.index);
    } catch (error) {
      throw error;
    }
  };

  const getProfile = async () => {
    try {
      const currentCurrency = getCurrency();

      const userData = await api(
        `${API_URL_PROFILE}?currency=${currentCurrency}`,
        {},
        profileSchema
      );

      setUserData(userData);
      return userData;
    } catch (error) {
      throw error;
    }
  };

  const updateProfile = async (formData: UpdatedProfileFormType) => {
    try {
      if (userData) {
        await api(
          API_URL_PROFILE,
          { method: "PUT", data: formData },
          profileSchema
        );

        const updatedProfile = { ...userData, ...formData };

        setUserData(updatedProfile);
        return updatedProfile;
      }

      return null;
    } catch (error) {
      throw error;
    }
  };

  const getUsers = async () => {
    try {
      const allUsers = await api(
        `${API_URL_USER}?limit=1000`,
        {},
        allUsersSchema
      );

      if (allUsers) {
        setUsers(allUsers.data);
      }
    } catch (error) {
      throw error;
    }
  };

  const updateUserById = async (
    userId: string,
    role: "user" | "admin" | ""
  ) => {
    try {
      await api(`${API_URL_USER}/${userId}`, { method: "PUT", data: { role } });
    } catch (error) {
      throw error;
    }
  };

  const deleteUser = async (userId: string) => {
    try {
      await api(`${API_URL_USER}/${userId}`, { method: "DELETE" });
    } catch (error) {
      throw error;
    }
  };

  const resetPassword = async (formData: ResetPasswordFormType) => {
    try {
      await api(API_URL_RESET_PASSWORD, {
        method: "PUT",
        data: formData,
      });
    } catch (error) {
      throw error;
    }
  };

  const changePassword = async (formData: ChangePasswordFormType) => {
    try {
      await api(API_URL_USER_NEW_PASSWORD, {
        method: "POST",
        data: formData,
      });
    } catch (error) {
      throw error;
    }
  };

  const feedback = async (formData: FeedbackFormType) => {
    try {
      await api(API_URL_FEEDBACK, {
        method: "POST",
        data: formData,
      });
    } catch (error) {
      throw handleErrorWithMessages(error, {
        "email must be an email":
          "Please enter a valid email address, e.g., email@gmail.com.",
        "ThrottlerException: Too Many Requests":
          "Too many requests, please try again later.",
      });
    }
  };

  const contextValue = useMemo(
    () => ({
      users,
      userData,
      signUp,
      logIn,
      logOut,
      getProfile,
      updateProfile,
      getUsers,
      updateUserById,
      deleteUser,
      resetPassword,
      changePassword,
      feedback,
    }),
    [
      users,
      userData,
      signUp,
      logIn,
      logOut,
      getProfile,
      updateProfile,
      getUsers,
      updateUserById,
      deleteUser,
      resetPassword,
      changePassword,
      feedback,
    ]
  );

  return (
    <userContext.Provider value={contextValue}>{children}</userContext.Provider>
  );
};
