import { useContext } from "react";
import classnames from "classnames";

// helpers
import { getMembershipTitle } from "../../helpers/get-membership-title";

// hooks
import { useWindowSize } from "../../hooks/use-window-size/use-window-size";

// context
import { sessionContext } from "../../context/session-provider/SessionProvider";

// components
import Modal from "../modal/Modal";
import Button from "../button/Button";
import Spinner from "../spinner/Spinner";
import {
  CourseType,
  VideoType,
} from "../../context/course-provider/CourseProvider.types";
import { MembershipTypesType } from "../../context/membership-provider/MembershipProvider.types";

// styles
import styles from "./PreviewCartModal.module.scss";

type PreviewCartModalProps = {
  isOpen: boolean;
  isCartLoading: boolean;
  filteredCart: {
    courses: CourseType[];
    videos: VideoType[];
    membership: MembershipTypesType | null;
  } | null;
  totalPrice: string;
  onClose: () => void;
  handleOpenCart: () => void;
};

export function PreviewCartModal({
  isOpen,
  isCartLoading,
  filteredCart,
  totalPrice,
  onClose,
  handleOpenCart,
}: PreviewCartModalProps) {
  const { getRoleFromToken } = useContext(sessionContext);
  const role = getRoleFromToken();

  const { width } = useWindowSize();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      className={classnames(styles.modal, role ? styles.authModal : "")}
      variant="cart"
      width={width > 768 ? "340px" : "90%"}
    >
      <div className={styles.container}>
        {isCartLoading ? (
          <div className={styles.cartLoader}>
            <Spinner />
          </div>
        ) : (
          <>
            <div>
              {filteredCart ? (
                filteredCart.courses.length ||
                filteredCart.membership ||
                filteredCart.videos.length ? (
                  <>
                    <div>
                      <div className={styles.cartProductsWrapper}>
                        {filteredCart.courses.length
                          ? filteredCart.courses.map((cartProduct) => (
                              <div
                                className={styles.cartProduct}
                                key={cartProduct.id}
                              >
                                <div className={styles.cartProductInfo}>
                                  <p className={styles.title}>
                                    <span>Course:</span> {cartProduct.title}
                                  </p>
                                </div>

                                <div className={styles.pricesWrapper}>
                                  {"discountPrice" in cartProduct ? (
                                    <>
                                      {cartProduct.discountPrice ? (
                                        <p className={styles.newPrice}>
                                          € {cartProduct.discountPrice}
                                        </p>
                                      ) : null}
                                      <div
                                        className={
                                          cartProduct.discountPrice
                                            ? styles.diagonalLine
                                            : ""
                                        }
                                      >
                                        <p
                                          className={
                                            cartProduct.discountPrice
                                              ? styles.oldPrice
                                              : styles.price
                                          }
                                        >
                                          € {cartProduct.price}
                                        </p>
                                      </div>
                                    </>
                                  ) : null}
                                </div>
                              </div>
                            ))
                          : null}

                        {filteredCart.videos.length
                          ? filteredCart.videos.map((cartProduct) => (
                              <div
                                className={styles.cartProduct}
                                key={cartProduct.id}
                              >
                                <div className={styles.cartProductInfo}>
                                  <p className={styles.title}>
                                    <span>Video:</span> {cartProduct.title}
                                  </p>
                                </div>

                                <div className={styles.pricesWrapper}>
                                  {"discountPrice" in cartProduct ? (
                                    <>
                                      {cartProduct.discountPrice ? (
                                        <p className={styles.newPrice}>
                                          € {cartProduct.discountPrice}
                                        </p>
                                      ) : null}
                                      <div
                                        className={
                                          cartProduct.discountPrice
                                            ? styles.diagonalLine
                                            : ""
                                        }
                                      >
                                        <p
                                          className={
                                            cartProduct.discountPrice
                                              ? styles.oldPrice
                                              : styles.price
                                          }
                                        >
                                          € {cartProduct.price}
                                        </p>
                                      </div>
                                    </>
                                  ) : null}
                                </div>
                              </div>
                            ))
                          : null}

                        {filteredCart.membership ? (
                          <div className={styles.cartProduct}>
                            <div className={styles.cartProductInfo}>
                              <p className={styles.title}>
                                <span>Membership:</span>{" "}
                                {getMembershipTitle(
                                  filteredCart.membership.name
                                )}
                              </p>
                            </div>

                            <div className={styles.pricesWrapper}>
                              {filteredCart.membership.discountPrice ? (
                                <p className={styles.newPrice}>
                                  € {filteredCart.membership.discountPrice}
                                </p>
                              ) : null}
                              <div
                                className={
                                  filteredCart.membership.discountPrice
                                    ? styles.diagonalLine
                                    : ""
                                }
                              >
                                <p className={styles.oldPrice}>
                                  € {filteredCart.membership.price}
                                </p>
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>

                      <div className={styles.totalWrapper}>
                        <p>Total</p>
                        <p>€ {totalPrice}</p>
                      </div>
                    </div>
                    <div className={styles.buttonWrapper}>
                      <Button onClick={handleOpenCart}>View cart</Button>
                    </div>
                  </>
                ) : (
                  <h6 className={styles.emptyCartText}>
                    Your shopping cart is empty
                  </h6>
                )
              ) : null}
            </div>
          </>
        )}
      </div>
    </Modal>
  );
}
