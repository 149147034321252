import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";

// context
import { errorContext } from "../../../context/error/ErrorProvider";
import { membershipContext } from "../../../context/membership-provider/MembershipProvider";

// types
import type { MembershipTypesType } from "../../../context/membership-provider/MembershipProvider.types";

export function useMembershipAdminForm(membershipAdmin: MembershipTypesType) {
  const { error, success } = useContext(errorContext);
  const { updateMembershipType, addMembershipType } =
    useContext(membershipContext);

  const [membershipFormData, setMembershipFormData] =
    useState<MembershipTypesType>(membershipAdmin);
  const [benefits, setBenefits] = useState<{ id: string; value: string }[]>([]);

  useEffect(() => {
    setMembershipFormData(membershipAdmin);
    setBenefits(
      membershipAdmin.options.map((benefit) => ({
        id: uuidv4(),
        value: benefit,
      }))
    );
  }, [membershipAdmin]);

  const handleChangeMembershipAdminData = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;

      setMembershipFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    },
    [setMembershipFormData, error]
  );

  const handleChangeBenefitsData = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;

      setBenefits((prev) =>
        prev.map((benefit) => {
          if (benefit.id === name) {
            return {
              ...benefit,
              value,
            };
          } else {
            return benefit;
          }
        })
      );
    },
    [setBenefits]
  );

  const handleAddNewBenefit = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => {
      e.preventDefault();
      setBenefits((prev) => [...prev, { id, value: "" }]);
    },
    [setBenefits]
  );

  const handleDeleteBenefit = useCallback(
    (e: React.MouseEvent<SVGSVGElement, MouseEvent>, id: string) => {
      e.preventDefault();
      setBenefits((prev) => prev.filter((benefit) => benefit.id !== id));
    },
    [setBenefits]
  );

  const onSubmit = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      try {
        const updatedMembershipFormData = {
          name: membershipFormData.name,
          options: benefits
            .filter((benefit) => !!benefit.value)
            .map((benefit) => benefit.value),
          price: Number(membershipFormData.price),

          discountPrice: membershipFormData.discountPrice
            ? Number(membershipFormData.discountPrice)
            : null,
        };

        // edit
        if (membershipFormData.id) {
          await updateMembershipType(
            membershipFormData.id,
            updatedMembershipFormData
          );

          success("The membership has been successfully edited.");
        } else {
          // create
          await addMembershipType(updatedMembershipFormData);

          success("The membership has been successfully created.");
        }
      } catch (e) {
        error(e);
      }
    },
    [
      membershipFormData,
      benefits,
      error,
      success,
      updateMembershipType,
      addMembershipType,
    ]
  );

  const isDisabledSubmitButton = useMemo(
    () => !membershipFormData?.price,
    [membershipFormData]
  );

  return {
    membershipFormData,
    benefits,
    isDisabledSubmitButton,
    handleAddNewBenefit,
    handleDeleteBenefit,
    handleChangeMembershipAdminData,
    handleChangeBenefitsData,
    onSubmit,
  };
}

export function useMembershipAdminFetch() {
  const { error } = useContext(errorContext);
  const { getMembershipsTypes } = useContext(membershipContext);

  const [isMembershipsAdminLoading, setIsMembershipsAdminLoading] =
    useState(false);

  const getMembershipsData = async () => {
    try {
      setIsMembershipsAdminLoading(true);

      await getMembershipsTypes(true);
    } catch (err) {
      error(err);
    } finally {
      setIsMembershipsAdminLoading(false);
    }
  };

  useEffect(() => {
    getMembershipsData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { isMembershipsAdminLoading };
}
