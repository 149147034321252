import { v4 as uuidv4 } from "uuid";

// icons
import { DeleteIcon } from "../../../../assets/icons/DeleteIcon";

// components
import Button from "../../../../components/button/Button";
import Input from "../../../../components/input/Input";

// hooks
import { useMembershipAdminForm } from "../use-membership-admin";

// types
import type { MembershipTypesType } from "../../../../context/membership-provider/MembershipProvider.types";

// styles
import styles from "./MembershipAdminItem.module.scss";

type MembershipAdminItemProps = {
  membership: MembershipTypesType;
};

export default function MembershipAdminItem({
  membership,
}: MembershipAdminItemProps) {
  const {
    membershipFormData,
    benefits,
    isDisabledSubmitButton,
    handleAddNewBenefit,
    handleDeleteBenefit,
    handleChangeMembershipAdminData,
    handleChangeBenefitsData,
    onSubmit,
  } = useMembershipAdminForm(membership);

  const membershipType = (type: "ONE_MONTH" | "ONE_YEAR" | "THREE_YEARS") => {
    switch (type) {
      case "ONE_MONTH":
        return "Monthly";

      case "ONE_YEAR":
        return "Annual";

      case "THREE_YEARS":
        return "3 years plan";

      default:
        return "";
    }
  };

  const membershipPriceTime = (
    type: "ONE_MONTH" | "ONE_YEAR" | "THREE_YEARS"
  ) => {
    switch (type) {
      case "ONE_MONTH":
        return "Month";

      case "ONE_YEAR":
        return "Year";

      case "THREE_YEARS":
        return "Total";

      default:
        return "";
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.infoWrapper}>
        <div className={styles.viewBlock}>
          <p className={styles.title}>{membershipType(membership.name)}</p>
          <div className={styles.priceWrapper}>
            <p className={styles.newPrice}>
              €{" "}
              {Number(membership.discountPrice)
                ? membership.discountPrice
                : membership.price}
              <span>/{membershipPriceTime(membership.name)}</span>
            </p>
            {Number(membership.discountPrice) ? (
              <div className={styles.diagonalLine}>
                <p className={styles.oldPrice}>€ {membership.price}</p>
              </div>
            ) : null}
          </div>
        </div>
        <div className={styles.inputsPricesWrapper}>
          <Input
            variant="admin"
            label="Course price"
            name="price"
            value={membershipFormData.price}
            onChange={handleChangeMembershipAdminData}
          />

          <Input
            variant="admin"
            label="Discount price"
            name="discountPrice"
            value={membershipFormData.discountPrice || ""}
            onChange={handleChangeMembershipAdminData}
          />
        </div>
        <div className={styles.inputsBenefitsWrapper}>
          {benefits.map((benefit, index) => (
            <div className={styles.inputWrapper} key={benefit.id}>
              <Input
                variant="admin"
                name={benefit.id}
                label={`Benefit ${index + 1}`}
                value={benefit.value}
                onChange={handleChangeBenefitsData}
              />

              <div className={styles.removeIcon}>
                <DeleteIcon
                  onClick={(e) => handleDeleteBenefit(e, benefit.id)}
                />
              </div>
            </div>
          ))}

          <div className={styles.buttonWrapper}>
            <Button
              variant="outline"
              onClick={(e) => handleAddNewBenefit(e, uuidv4())}
            >
              Add Benefits
            </Button>
          </div>
        </div>
      </div>
      <div className={styles.buttonWrapper}>
        <Button onClick={(e) => onSubmit(e)} disabled={isDisabledSubmitButton}>
          Save
        </Button>
      </div>
    </div>
  );
}
