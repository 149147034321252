/**
 * Returns the title based on the plan name.
 *
 * @param {string} membershipName - The name of the plan.
 * @returns {string} - The title corresponding to the plan name.
 */
export function getMembershipTitle(membershipName: string) {
  if (membershipName === "ONE_MONTH") {
    return "Monthly";
  }

  if (membershipName === "ONE_YEAR") {
    return "Annual";
  }

  return "3 years plan";
}
