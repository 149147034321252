import { z } from "zod";

export const sourceSchema = z.object({
  createdAt: z.number(),
  fileName: z.string(),
  fileSize: z.number(),
  key: z.string(),
  extension: z.string(),
});

export const videoSchema = z.object({
  isFree: z.boolean(),
  id: z.string(),
  courseId: z.string().nullable().optional(),
  course: z.any(),
  title: z.string(),
  description: z.string(),
  picture: z.string(),
  source: sourceSchema.nullable().optional(),
  price: z.string().nullable().optional(),
  discountPrice: z.string().nullable().optional(),
  free: z.boolean().nullable().optional(),
  createdAt: z.string(),
  updatedAt: z.string(),
});

export const courseSchema = z.object({
  id: z.string(),
  title: z.string(),
  price: z.string().nullable(),
  discountPrice: z.string().nullable(),
  picture: z.string(),
  willLearn: z.array(z.string()).nullable(),
  willGet: z.array(z.string()).nullable(),
  isPublished: z.boolean(),
  category: z.string(),
  createdAt: z.string(),
  updatedAt: z.string(),
  videos: z.array(videoSchema).nullable().optional(),
});

export const allCoursesSchema = z.object({
  total: z.number(),
  data: z.array(courseSchema),
});

export const allVideosSchema = z.object({
  total: z.number(),
  data: z.array(videoSchema),
});

export const addCourseFormSchema = z.object({
  title: z.string(),
  price: z.string(),
  discountPrice: z.string().nullable().optional(),
  picture: z.string(),
  willLearn: z.array(z.string()).nullable(),
  willGet: z.array(z.string()).nullable(),
  isPublished: z.boolean(),
  category: z.string(),
});

export const addVideoFormSchema = z.object({
  title: z.string(),
  description: z.string(),
  courseId: z.string(),
  source: sourceSchema,
  isFree: z.boolean(),
  picture: z.string(),
  price: z.string().nullable().optional(),
  discountPrice: z.string().nullable().optional(),
});

export const loginSchema = z.object({
  accessToken: z.string(),
  accessTokenExpiresIn: z.number(),
  refreshToken: z.string(),
  refreshTokenExpiresIn: z.number(),
});
