import React, { useState } from "react";
import classnames from "classnames";

// icons
import { ArrowDownIcon } from "../../../../assets/icons/ArrowDownIcon";

// components
import { VideoItem } from "../video-item/VideoItem";

// types
import type {
  CourseType,
  VideoType,
} from "../../../../context/course-provider/CourseProvider.types";

// styles
import styles from "./MyCourseItem.module.scss";
import { VideoModal } from "../../../../components/video-modal/VideoModal";

type MyCourseItemProps = {
  course: CourseType;
};

export function MyCourseItem({ course }: MyCourseItemProps) {
  const [isOpenCourseInfo, setIsOpenCourseInfo] = useState(false);

  const [isOpenVideoModal, setIsOpenVideoModal] = useState<VideoType | null>(
    null
  );

  return (
    <div className={styles.container}>
      <div
        className={styles.courseInfo}
        onClick={() => setIsOpenCourseInfo((prev) => !prev)}
      >
        <div className={styles.titleWrapper}>
          <p className={styles.title}>{course.title}</p>
          <div className={styles.videos}>{course.videos?.length} Videos</div>
        </div>
        <div
          className={classnames(
            styles.iconWrapper,
            isOpenCourseInfo ? styles.open : ""
          )}
        >
          <ArrowDownIcon />
        </div>
      </div>

      <div
        className={classnames(
          styles.mainContent,
          isOpenCourseInfo ? styles.open : ""
        )}
      >
        {course.videos
          ? course.videos
              .sort(
                (a, b) =>
                  new Date(a.createdAt).getTime() -
                  new Date(b.createdAt).getTime()
              )
              .map((video, index) => (
                <VideoItem
                  video={video}
                  key={video.id}
                  courseId={course.id}
                  type="user"
                  videoIndex={index}
                  setIsOpenVideoModal={setIsOpenVideoModal}
                />
              ))
          : null}
      </div>

      {isOpenVideoModal ? (
        <VideoModal
          isOpen={Boolean(isOpenVideoModal)}
          onClose={() => {
            setIsOpenVideoModal(null);
          }}
          video={isOpenVideoModal}
          type="user"
        />
      ) : null}
    </div>
  );
}
