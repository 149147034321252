import React, { useCallback } from "react";
import { isValidPhoneNumber } from "react-phone-number-input";

// hooks
import { useProfileAdminFetch, useProfileForm } from "../use-profile-user";

// helpers
import { formattedDate } from "../../../../helpers/format-date";

// components
import Spinner from "../../../../components/spinner/Spinner";
import Input from "../../../../components/input/Input";
import Button from "../../../../components/button/Button";

// styles
import styles from "./ProfileForm.module.scss";

export function ProfileForm() {
  const { isProfileLoading } = useProfileAdminFetch();
  const {
    profileFormData,
    isDisabledSubmitButton,
    validatePassword,
    handleChangeProfileData,
    setProfileFormData,
    onSubmit,
  } = useProfileForm();

  const validatePhone = useCallback(
    (phone: string) => {
      return phone?.length === 0 || isValidPhoneNumber(phone)
        ? {
            error: false,
            message: "",
          }
        : {
            error: true,
            message: "",
          };
    },
    [profileFormData]
  );

  const handleFocus = () => {
    if (!profileFormData?.phone?.startsWith("+")) {
      setProfileFormData((prev) => ({ ...prev, phone: "+" + prev?.phone }));
    }
  };

  return (
    <form className={styles.formWrapper}>
      <p className={styles.title}>My info</p>
      {isProfileLoading ? (
        <Spinner />
      ) : (
        <>
          <div className={styles.description}>
            <p>
              Last access to site: {formattedDate(profileFormData?.updatedAt)}
            </p>
            <p>
              First access to site: {formattedDate(profileFormData?.createdAt)}
            </p>
          </div>
          <div className={styles.inputWrapper}>
            <Input
              variant="admin"
              label="First name"
              name="firstName"
              value={profileFormData?.firstName}
              onChange={handleChangeProfileData}
            />

            <Input
              variant="admin"
              label="Last name"
              name="lastName"
              value={profileFormData?.lastName}
              onChange={handleChangeProfileData}
            />
          </div>
          <div className={styles.inputWrapper}>
            <Input
              variant="admin"
              name="password"
              label="Current password"
              type="password"
              value={profileFormData?.password}
              onChange={handleChangeProfileData}
              validation={validatePassword("password")}
            />
            <Input
              variant="admin"
              type="password"
              name="newPassword"
              label="New password"
              value={profileFormData?.newPassword}
              onChange={handleChangeProfileData}
              validation={validatePassword("newPassword")}
            />
          </div>
          <div className={styles.inputWrapper}>
            <Input
              variant="admin"
              label="Phone"
              type="text"
              name="phone"
              value={profileFormData?.phone}
              onChange={handleChangeProfileData}
              onFocus={handleFocus}
              validation={validatePhone(profileFormData?.phone || "")}
              required
            />
          </div>
          <div className={styles.profileFooter}>
            <Button
              onClick={(e) => onSubmit(e)}
              width="auto"
              disabled={isDisabledSubmitButton}
            >
              Submit
            </Button>
          </div>
        </>
      )}
    </form>
  );
}
