import React from "react";

// icons
import { ArrowLeftIcon } from "../../assets/icons/ArrowLeftIcon";

// hooks
import { useWindowSize } from "../../hooks/use-window-size/use-window-size";
import { useVideoFetch } from "../../side/client/my-courses-user-screen/video-item/use-video";

// components
import Spinner from "../spinner/Spinner";
import Modal from "../modal/Modal";

// types
import type { VideoType } from "../../context/course-provider/CourseProvider.types";

// styles
import styles from "./VideoModal.module.scss";

type VideoModalProps = {
  isOpen: boolean;
  video: VideoType;
  onClose: () => void;
  type: "admin" | "guest" | "user";
};

export function VideoModal({ isOpen, video, type, onClose }: VideoModalProps) {
  const { width } = useWindowSize();

  const { isVideoLoading, videoUrl } = useVideoFetch(video, isOpen, type);

  return (
    <Modal
      isOpen={Boolean(isOpen)}
      onClose={onClose}
      header={""}
      size="fullScreen"
      width={width > 767 ? "75%" : "97%"}
      className={styles.modal}
    >
      <div className={styles.videoPlayer}>
        {width < 768 ? (
          <div>
            <ArrowLeftIcon
              width={24}
              height={24}
              onClick={onClose}
              className={styles.backArrow}
            />
          </div>
        ) : null}
        {isVideoLoading ? (
          <div className={styles.videoLoader}>
            <Spinner size="large" />
          </div>
        ) : videoUrl ? (
          <video className={styles.videoFrame} controls>
            <source src={videoUrl || ""} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : (
          <div className={styles.emptyVideo}>
            <h6>Video is unavailable</h6>
          </div>
        )}
      </div>
    </Modal>
  );
}
