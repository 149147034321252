import React from "react";

// styles
import styles from "../General.module.scss";

export function PrivacyPolicyScreen() {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h4>Privacy Policy</h4>
        <p>17.05.2024</p>
        <p>
          Your access and use of the Services constitutes your agreement to be
          bound by these Policy. You understand and agree that the Service is
          provided on an AS IS and AS AVAILABLE basis as amended from time to
          time.
        </p>
      </div>

      <p>
        In this Privacy Policy <strong>ZENIT MARKETING LTD</strong> may be
        referred to as us/our/we. The collection, use, storage and disclosure of
        your personal information is governed by this Privacy Policy. By using
        our site, you agree that we may use/disclose/collect personal
        information in accordance with the current Privacy Policy.{" "}
        <strong>ZENIT MARKETING LTD</strong> reserves the right to change our
        Privacy Policy. All changes will be posted on our website. We recommend
        that you check our website from time to time for such changes.
      </p>
      <p>
        Personal information is also considered to be a viewpoint and
        information used to identify a person.
      </p>

      <h5>What types of personal data we collect</h5>
      <p>Here you can see a list of personal data that our company collects.</p>
      <ul>
        <li>name;</li>
        <li>email address;</li>
        <li>age or date of birth;</li>
        <li>geolocation;</li>
        <li>the page traffic;</li>
        <li>the ID and type of your device;</li>
        <li>credit card information;</li>
        <li>IP address;</li>
        <li>standard data of your web log;</li>
        <li>information about your PC and the network connection;</li>
        <li>phone number;</li>
        <li>
          Additional information that you provide through our site or indirectly
          through applications or online services or other sites from which you
          authorize us to collect information.
        </li>
      </ul>

      <p>
        Such personal information may be collected from you or from third
        parties. We gather this data in case:
      </p>
      <ul>
        <li>Interactions with our website, advertising, services;</li>
        <li>
          when communicating via email in case you share information from other
          social apps/sites.
        </li>
      </ul>

      <h5>Why we use, collect, and disclose personal information</h5>
      <p>
        Our company may use/store/disclose personal information for the purposes
        described next:
      </p>
      <ul>
        <li>to enable you to access and use our website and services;</li>
        <li>to maintain, enhance, secure our website;</li>
        <li>
          to send service, administrative messages/reminders/technical
          notifications or security alert messages about the information you
          requested;
        </li>
        <li>
          to comply with legal obligations (resolutions of any complexity that
          may arise with any of our Users) and to enforce consents;
        </li>
        <li>
          to send advertising and marketing offers that may potentially be of
          interest to you (including information that may be sent by our
          business partners).
        </li>
      </ul>

      <p>
        Also, our company reserves the right to transfer your data to a third
        party. Such data transmitted to a third party may be aggregated to
        create anonymous consumer information. In turn, the consumer information
        may be used to track User interests, preferences and to personalize your
        experience of the services you receive.
      </p>

      <h5>How long We Keep Your Personal Data</h5>
      <p>
        The company will retain the data for the period necessary to fulfill the
        purposes specified in this Privacy Policy. We may also retain data for
        longer if permitted by local laws to which the Company is subject.
      </p>

      <h5>Do we use your personal information for direct marketing</h5>
      <p>
        The User can receive information/advertisements from us or our business
        partners. You can get this kind of information by email, sms, Telegram,
        Facebook messaging, or other ways of contacting you.
      </p>

      <h5>To whom do we disclose your personal information</h5>
      <p>
        We may disclose personal information to persons identified in this
        Policy:
      </p>

      <ul>
        <li>our employees or their related corporate bodies;</li>
        <li>
          to service providers (including the providers that operate our
          websites or our business and connect with our services to you),
          third-party providers, editors;
        </li>
        <li>professional advisors and agents;</li>
        <li>payment systems operators;</li>
        <li>our current/potential business partner.</li>
      </ul>

      <h5>Using our website and cookies</h5>
      <p>
        When you use/visit our Website, we may collect personal information. Our
        Web site does not identify each User personally by browsing information,
        but we may in some cases collect certain information about your use of
        our site: which pages you view, the time and date you visit, your IP
        address. Cookies or other tracking tools are also used to improve our
        service. Cookies are a necessary file stored on your phone or PC to
        enable the company to identify you on different websites/services.
        Please consider that the service may not work properly if you disable
        cookies in your browser. Cookies are used to help collect personal data.
        Our company views cookies as any other personal information.
      </p>

      <h5>New Purposes</h5>
      <p>
        If our company wants to use your personal data for new purposes that are
        not described at the moment, we will provide you with a new notification
        describing the update.
      </p>

      <h5>Testimonials</h5>
      <p>
        By using our website, you agree to the use of testimonials or reviews
        that you provided about the service. We may also use your testimonials
        or reviews for advertising purposes.
      </p>

      <h5>Security</h5>
      <p>
        Data related to personal information may be stored in a
        physical/electronic copy or electronic format. Our company takes
        reasonable precautions to protect personal information. The company may
        use a variety of administrative, physical, and technical precautions if
        required to ensure security.
      </p>

      <h5>Links</h5>
      <p>
        Our website{" "}
        <a href="https://pokerpowerhouse.uk">https://pokerpowerhouse.uk</a> may
        contain links to third-party websites. Such links may not be updated or
        maintained. We have no control over the sites, are not responsible for
        the content, privacy policies of the linked sites, and have no ownership
        rights in the linked sites unless otherwise specified. We recommend that
        you first read the privacy policy of such sites before you use them, as
        privacy policy may vary.
      </p>

      <h5>Accessing or correcting your personal information</h5>
      <p>
        By contacting our support, you can get access to personal data that is
        currently stored. Also consider that in some cases we cannot give you
        access to your personal data. In such cases, we will explain the reason.
      </p>

      <h5>Making a complaint</h5>
      <p>
        If you have a complaint or you think that we have violated privacy, you
        can contact us by email{" "}
        <a href="mailto:support@pokerpowerhouse.uk">
          support@pokerpowerhouse.uk
        </a>
        . Provide a detailed description of the complaint, your name, email
        address, phone number. Our support will respond to you within a
        reasonable amount of time.
      </p>

      <h5>Changes to this Privacy Policy</h5>
      <p>
        Our Company reserves the right to revise this Policy. For example, in
        accordance with changes in the law, regulatory changes, new policies or
        the requirements of any authority. You should visit this page to review
        changes to the Privacy Policy.
      </p>

      <h5>Contact Us</h5>
      <p>
        You can get more information about our policy, request access or
        correction of your personal information, or file a complaint by
        contacting us:{" "}
        <a href="mailto:support@pokerpowerhouse.uk">
          support@pokerpowerhouse.uk
        </a>
        .
      </p>
    </div>
  );
}
