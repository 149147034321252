import { useContext } from "react";

// context
import { cartContext } from "../../../context/cart-provider/CartProvider";
import { currencyContext } from "../../../context/currency-provider/CurrencyProvider";

// icons
import { CloseIcon } from "../../../assets/icons/CloseIcon";

// types
import type { CourseType } from "../../../context/course-provider/CourseProvider.types";

// styles
import styles from "./CartModalCourse.module.scss";

type CartModalCourseProps = {
  cartProduct: CourseType;
};

export function CartModalCourse({ cartProduct }: CartModalCourseProps) {
  const { deleteCourseFromCart } = useContext(cartContext);
  const { getCurrencySymbol } = useContext(currencyContext);

  const courseCategory = (category: string) => {
    switch (category) {
      case "beginner":
        return "Beginners courses:";

      case "pro":
        return "Pro courses:";

      case "vip":
        return "VIP courses:";

      default:
        return "";
    }
  };

  return (
    <div className={styles.container}>
      <div>
        <img src={cartProduct.picture} alt={cartProduct.title} />
      </div>
      <div className={styles.infoWrapper}>
        <p>
          {courseCategory(cartProduct.category)}{" "}
          <span>{cartProduct.title}</span>
        </p>

        <div className={styles.info}>{cartProduct.videos?.length} Videos</div>
      </div>

      <div className={styles.priceAndQuantityWrapper}>
        <div className={styles.pricesWrapper}>
          {cartProduct.discountPrice ? (
            <p>
              {getCurrencySymbol()} {cartProduct.discountPrice}
            </p>
          ) : null}
          <div className={cartProduct.discountPrice ? styles.diagonalLine : ""}>
            <p className={cartProduct.discountPrice ? styles.oldPrice : ""}>
              {getCurrencySymbol()} {cartProduct.price}
            </p>
          </div>
        </div>
        <div
          className={styles.removeWrapper}
          onClick={() => deleteCourseFromCart(cartProduct.id)}
        >
          <CloseIcon color="#A21916" /> Remove
        </div>
      </div>
    </div>
  );
}
