import React, { useContext, useEffect, useState } from "react";

// context
import { errorContext } from "../../../../context/error/ErrorProvider";
import { userContext } from "../../../../context/user-provider/UserProvider";
import { membershipContext } from "../../../../context/membership-provider/MembershipProvider";

// helpers
import { getMembershipTitle } from "../../../../helpers/get-membership-title";

// components
import Spinner from "../../../../components/spinner/Spinner";

// types
import type { OrderType } from "../../../../context/order-provider/OrderProvider.types";

// styles
import styles from "./OrderHistory.module.scss";
import { formattedDate } from "../../../../helpers/format-date";

export function OrderHistory() {
  const { error } = useContext(errorContext);
  const { userData } = useContext(userContext);
  const { getMembershipTypeById } = useContext(membershipContext);

  const [isOrdersLoading, setIsOrdersLoading] = useState(false);
  const [ordersData, setOrdersData] = useState<OrderType[] | null>(null);

  const courseCategory = (category: string) => {
    switch (category) {
      case "beginner":
        return "Beginners courses:";

      case "pro":
        return "Pro courses:";

      case "vip":
        return "VIP courses:";

      default:
        return "";
    }
  };

  useEffect(() => {
    const fetchOrdersData = async () => {
      if (userData) {
        try {
          setIsOrdersLoading(true);
          const updatedUserData = await Promise.all(
            userData.orders.map(async (order) => {
              if (order.membershipTypeId) {
                const membershipResponse = await getMembershipTypeById(
                  order.membershipTypeId
                );

                return {
                  ...order,
                  membershipTypeId: membershipResponse
                    ? membershipResponse.name
                    : null,
                };
              }
              return order;
            })
          );

          setOrdersData(updatedUserData);
        } catch (e) {
          error(e);
        } finally {
          setIsOrdersLoading(false);
        }
      }
    };

    fetchOrdersData();
  }, [userData]);

  return (
    <section className={styles.container}>
      <p className={styles.title}>Order history</p>
      {isOrdersLoading ? (
        <Spinner />
      ) : (
        <div className={styles.tableWrapper}>
          <div className={styles.tableRowHead}>
            <div>Type</div>

            <div>Amount</div>

            <div>Status</div>

            <div>Date</div>
          </div>
          {ordersData ? (
            ordersData
              .sort(
                (a, b) =>
                  new Date(b.createdAt).getTime() -
                  new Date(a.createdAt).getTime()
              )
              .map((order) => {
                return (
                  <div key={order.id} className={styles.tableRowBody}>
                    <div className={styles.orderItem}>
                      {order.courses?.map((course) => (
                        <div className={styles.rowTitle} key={course.id}>
                          {courseCategory(course.category)} {course.title}
                        </div>
                      ))}

                      {order.videos?.map((video) => (
                        <div className={styles.rowTitle} key={video.id}>
                          Videos: {video.title}
                        </div>
                      ))}

                      {order.membershipTypeId ? (
                        <div className={styles.rowTitle}>
                          Membership:{" "}
                          {getMembershipTitle(order.membershipTypeId)}
                        </div>
                      ) : null}
                    </div>

                    <div className={styles.rowPrice}>€{order.total}</div>

                    <div className={styles.rowStatus}>{order.status}</div>

                    <div className={styles.rowDate}>
                      {formattedDate(order.updatedAt, false)}
                    </div>
                  </div>
                );
              })
          ) : (
            <div>At the moment, unfortunately, payments are not available.</div>
          )}
        </div>
      )}
    </section>
  );
}
