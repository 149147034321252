import { useMemo } from "react";

// images
import brain_src from "../../../../assets/images/brain.png";
import brain_storm_src from "../../../../assets/images/brain_storm.png";
import eye_icon_src from "../../../../assets/images/eye.png";
import graphic_src from "../../../../assets/images/graphic.png";
import medal_src from "../../../../assets/images/circum_medal.png";
import smile_src from "../../../../assets/images/smile.png";

// context
import { useCoursesMenuFetch } from "../../../../components/header/courses-menu/use-courses-menu";

export function useLearningBlock() {
  const {
    isCoursesLoading,
    beginnerCoursesData,
    proCoursesData,
    vipCoursesData,
  } = useCoursesMenuFetch();

  const learningCards = useMemo(() => {
    if (
      beginnerCoursesData.length &&
      proCoursesData.length &&
      vipCoursesData.length
    ) {
      return [
        {
          icon: eye_icon_src,
          title: "Play Poker How to Become a Pro",
          description:
            "Go from the idea of learning how to play poker to the essential parts of the poker game. You learn about hands and terms, a few ways to play. This class is good for all who play poker. Learn the most popular games and know: How to play Poker Texas Hold'em and How to play Omaha Poker",
          courseId: proCoursesData?.[1].id || "",
        },
        {
          icon: brain_src,
          title: "How to Study Poker for Mastery",
          description:
            "Learn to analyze your opponents' moves, then adjust to their game styles and use very creative methods to build your success in a wide range of types of poker games. The definition and rules of how to play 3 card poker is presented. You will become the best player who can implement these techniques with no effort.",
          courseId: proCoursesData?.[2].id || "",
        },
        {
          icon: smile_src,
          title: "Learn Poker Game: The Art of Bluffing",
          description:
            "Good bluffers need to watch body language for tells like when somebody taps his foot, or lifts a shoulder when has a good hand at poker. It's important to learn to play poker rules, find advice for how to play poker for beginners, and be successful at the game.",
          courseId: proCoursesData?.[1].id || "",
        },
        {
          icon: brain_storm_src,
          title: "Learn to Play Poker Online: Tournament Strategies",
          description:
            "Become a Poker Tournament specialist by understanding what you need to know. You'll be able to develop new strategies designed for tournaments, improve your style, and look for efficient methods to win in both online types of poker live. Learn how to play strip poker and win.",
          courseId: beginnerCoursesData?.[1].id || "",
        },
        {
          icon: medal_src,
          title:
            "Bankroll Management & Risk Mitigation: How to Play Video Poker",
          description:
            "Put a perfect plan down which consists of good bankroll management, a program of evaluations of risk to throw and would be able to accept the swings of the game and poker game would be profitable, ensuring sustained profitability. Learn how to play poker and manage your resources wisely.",
          courseId: beginnerCoursesData?.[1].id || "",
        },
        {
          icon: graphic_src,
          title: "Engaging in Live Action and Navigating Dynamics",
          description:
            "Get to know the particular principles of each realm, the etiquette, pacing, and strategic live games zone, and online card games and be sure what you win! Look through several different types of poker games and what makes them special.",
          courseId: proCoursesData?.[2].id || "",
        },
      ];
    }
  }, [beginnerCoursesData, proCoursesData, vipCoursesData]);

  return {
    isCoursesLoading,
    learningCards,
  };
}
