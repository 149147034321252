//consts
import { PATHS } from "../../route/route.controls";

export const ADMIN_TABS = [
  {
    title: "Membership plans",
    to: PATHS.adminMemberships,
  },
  {
    title: "Blog",
    to: PATHS.adminBlog,
  },
  {
    title: "Users",
    to: PATHS.adminUsers,
  },
  {
    title: "Courses",
    to: PATHS.adminCourses,
  },
];
