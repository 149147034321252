import { generatePath, useNavigate } from "react-router-dom";

// consts
import { PATHS } from "../../../../route/route.controls";

// types
import type { LearningCardType } from "../learning-block/LearningBlock.types";

// components
import Button from "../../../../components/button/Button";

// hooks
import { useWindowSize } from "../../../../hooks/use-window-size/use-window-size";

// styles
import styles from "./LearningCard.module.scss";

type Props = {
  learningCard: LearningCardType;
};

export function LearningCard({ learningCard }: Props) {
  const { width } = useWindowSize();
  const navigate = useNavigate();

  const generatedLink = generatePath(PATHS.course, {
    courseId: learningCard.courseId,
  });

  return (
    <div
      className={styles.learningCard}
      onClick={() => navigate(generatedLink)}
    >
      <h6 className={styles.learningCardTitle}>{learningCard.title}</h6>
      <p className={styles.learningCardDescription}>
        {learningCard.description}
      </p>
      <img
        className={styles.icon}
        src={learningCard.icon}
        alt="learning card"
      />
      <Button
        onClick={(e) => {
          e.stopPropagation();
          navigate(generatedLink);
        }}
        className={styles.learningButton}
        color="secondary"
        width={width > 768 ? "auto" : "full"}
      >
        Learn more
      </Button>
    </div>
  );
}
