import React, { useContext } from "react";

// components
import { Outlet } from "react-router-dom";
import { Header } from "../../components/header/Header";
import { Footer } from "../../components/footer/Footer";
import { Tabs } from "../../components/tabs/Tabs";

// consts
import { ADMIN_TABS } from "./AdminLayout.consts";

// hooks
import { useAuthFetch } from "../../context/user-provider/use-auth";

// styles
import styles from "./AdminLayout.module.scss";

export function AdminLayout(): JSX.Element {
  const { isAuthLoading } = useAuthFetch();

  return (
    <div className={styles.mainSection}>
      <Header type="admin" />
      <div className={styles.wrapper}>
        <Tabs tabs={ADMIN_TABS} />

        <Outlet />
      </div>

      <Footer />
    </div>
  );
}
