import React, { ForwardedRef, forwardRef, useContext, useEffect } from "react";

// images
import arrow_image from "../../../../assets/images/arrow_middle_background.png";

// helpers
import { sortMemberships } from "../../../../helpers/sort-memberships";

// hooks
import { useMembershipFetch } from "../use-membership";

// context
import { membershipContext } from "../../../../context/membership-provider/MembershipProvider";

// components
import Spinner from "../../../../components/spinner/Spinner";
import { PlansCard } from "../plansCard/PlansCard";

// styles
import styles from "./Plans.module.scss";

export const Plans = forwardRef(function Plans(
  _,
  forwardRef: ForwardedRef<HTMLDivElement>
) {
  const { membershipsTypesData } = useContext(membershipContext);

  const membershipsData = sortMemberships(membershipsTypesData || []);

  const { isMembershipsLoading } = useMembershipFetch();

  if (isMembershipsLoading) {
    return <Spinner />;
  }

  return (
    <section className={styles.plansSection} ref={forwardRef}>
      <div className={styles.contentWrapper}>
        <div className={styles.plansgMain}>
          <img
            src={arrow_image}
            alt="Arrow"
            className={styles.backgroundImage}
          />
          <div className={styles.descriptionBlock}>
            <h3 className={styles.plansTitle}>Our pricing plans</h3>

            <p className={styles.plansDescription}>
              Pick the plan that fits your preferences
            </p>
          </div>
        </div>
        <div className={styles.plansCardsWrapper}>
          {membershipsData
            ? membershipsData.map((membership, index) => (
                <PlansCard plan={membership} key={index} />
              ))
            : null}
        </div>
      </div>
    </section>
  );
});
