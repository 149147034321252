import React, { FunctionComponent, useContext, useMemo, useState } from "react";

// context
import { apiContext } from "../api-provider/ApiProvider";

// consts
import { API_URL_ORDER } from "./OrderProvider.consts";

// schemas
import {
  allOrdersSchema,
  orderSchema,
  orderStatusSchema,
} from "./OrderProvider.schemas";

// types
import type {
  OrderContext,
  OrderProviderProps,
  OrderType,
} from "./OrderProvider.types";
import { z } from "zod";

export const orderContext = React.createContext({} as OrderContext);

export const OrderProvider: FunctionComponent<OrderProviderProps> = (props) => {
  const { api } = useContext(apiContext);

  const { children } = props;

  const [ordersData, setOrdersData] = useState<OrderType[] | null>(null);
  const [orderById, setOrderById] = useState<OrderType | null>(null);

  const getOrders = async () => {
    try {
      const response = await api(API_URL_ORDER, {}, allOrdersSchema);

      if (response) {
        const allOrders = response.data;

        setOrdersData(allOrders);
        return allOrders;
      }

      return null;
    } catch (error) {
      throw error;
    }
  };

  const getOrderById = async (orderId: string) => {
    try {
      const response = await api(
        `${API_URL_ORDER}/${orderId}`,
        {},
        orderSchema
      );

      if (response) {
        const currentOrder = response;

        setOrderById(currentOrder);
        return currentOrder;
      }

      return null;
    } catch (error) {
      throw error;
    }
  };

  const createOrder = async (
    idsCourse: string[],
    idsVideo: string[],
    membershipTypeId: string
  ) => {
    try {
      const orderData = await api(
        API_URL_ORDER,
        {
          method: "POST",
          data: {
            ...(idsCourse.length > 0 && { idsCourse }),
            ...(idsVideo.length > 0 && { idsVideo }),
            ...(membershipTypeId && { membershipTypeId }),
          },
        },
        z.any()
      );

      return orderData;
    } catch (error) {
      throw error;
    }
  };

  const contextValue = useMemo(
    () => ({ getOrders, getOrderById, createOrder, ordersData, orderById }),
    [getOrders, getOrderById, createOrder, ordersData, orderById]
  );

  return (
    <orderContext.Provider value={contextValue}>
      {children}
    </orderContext.Provider>
  );
};
