import { PATHS } from "../../route/route.controls";

export const guestNavigationsLinks = [
  {
    title: "Home",
    to: PATHS.index,
  },
  {
    title: "Membership",
    to: PATHS.membership,
  },
  {
    title: "Courses",
    to: PATHS.courseOverview,
  },
  {
    title: "Blog",
    to: PATHS.blog,
  },
  {
    title: "About us",
    to: PATHS.aboutUs,
  },
  // {
  //   title: "Forum",
  //   to: "forum",
  // },
];

export const userNavigationLinks = [
  {
    title: "Profile",
    to: PATHS.profile,
  },
  {
    title: "My Courses",
    to: PATHS.myCourses,
  },
];
