import React, { SVGProps } from "react";

const SuccessIcon = ({
  className,
  color = "#FDFDFD",
  width = 95,
  height = 95,
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="96"
      height="95"
      viewBox="0 0 96 95"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1390_65930)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M48.5 77C42.5666 77 36.7664 75.2406 31.8329 71.9441C26.8994 68.6477 23.0543 63.9623 20.7837 58.4805C18.513 52.9987 17.9189 46.9667 19.0764 41.1473C20.234 35.3279 23.0913 29.9824 27.2868 25.7868C31.4824 21.5912 36.8279 18.734 42.6473 17.5765C48.4667 16.4189 54.4988 17.013 59.9806 19.2836C65.4623 21.5542 70.1477 25.3994 73.4441 30.3329C76.7406 35.2664 78.5 41.0666 78.5 47C78.5 54.9565 75.3393 62.5871 69.7132 68.2132C64.0871 73.8393 56.4565 77 48.5 77ZM31.514 53.765C30.8689 53.1128 30.507 52.2324 30.507 51.315C30.507 50.3976 30.8689 49.5172 31.514 48.865L33.2801 47.109C33.5804 46.806 33.9385 46.5663 34.3332 46.4044C34.7279 46.2424 35.1512 46.1614 35.5778 46.1662C36.0045 46.1709 36.4258 46.2613 36.8168 46.4321C37.2078 46.6028 37.5605 46.8503 37.854 47.16L40.974 50.397C41.1228 50.554 41.302 50.679 41.5006 50.7644C41.6993 50.8498 41.9133 50.8939 42.1295 50.8939C42.3458 50.8939 42.5598 50.8498 42.7585 50.7644C42.9571 50.679 43.1362 50.554 43.285 50.397L59.185 34.007C59.4806 33.6951 59.8363 33.4463 60.2306 33.2754C60.6248 33.1045 61.0496 33.0151 61.4793 33.0126C61.909 33.0101 62.3347 33.0945 62.731 33.2607C63.1272 33.427 63.4857 33.6717 63.785 33.98L65.5 35.714C66.1294 36.3636 66.4813 37.2325 66.4813 38.137C66.4813 39.0415 66.1294 39.9104 65.5 40.56L44.391 62.011C44.0964 62.3159 43.744 62.559 43.3542 62.7259C42.9645 62.8928 42.5453 62.9802 42.1213 62.983C41.6973 62.9858 41.2771 62.9039 40.8852 62.7422C40.4932 62.5804 40.1376 62.342 39.839 62.041L31.514 53.765Z"
          fill="#699F4C"
        />
      </g>
      <defs>
        <clipPath id="clip0_1390_65930">
          <rect
            width="60"
            height="60"
            fill="white"
            transform="translate(18.5 17)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export { SuccessIcon };
