import React from "react";

// components
import { Select } from "../../../components/select/select";
import Button from "../../../components/button/Button";
import Spinner from "../../../components/spinner/Spinner";

// hooks
import { useUsersAdminFetch, useUsersAdminForm } from "./use-users-admin";

// styles
import styles from "./UsersAdminScreen.module.scss";

export function UsersAdminScreen() {
  const { isUsersLoading } = useUsersAdminFetch();
  const {
    userFormData,
    usersEmailsOptions,
    profileRoleOptions,
    handleChangeUserData,
    onSubmit,
  } = useUsersAdminForm();

  return (
    <form className={styles.formWrapper}>
      {isUsersLoading ? (
        <Spinner className={styles.loader} />
      ) : (
        <>
          <div className={styles.selectWrapper}>
            <Select
              placeholder="User"
              options={usersEmailsOptions}
              value={usersEmailsOptions.find(
                ({ value }) => value === userFormData.email_id
              )}
              onChange={(value) => handleChangeUserData(value, "email_id")}
            />
            <Select
              placeholder="Role"
              options={profileRoleOptions}
              value={profileRoleOptions.find(
                ({ value }) => value === userFormData.role
              )}
              onChange={(value) => handleChangeUserData(value, "role")}
            />
          </div>
          <div className={styles.buttonWrapper}>
            <Button onClick={(e) => onSubmit(e)} width="auto">
              Submit
            </Button>
          </div>
        </>
      )}
    </form>
  );
}
