import { Link } from "react-router-dom";
import { PATHS } from "../../route/route.controls";

export const footerData = [
  {
    title: "Our address",
    items: [
      "Zenit Marketing LTD",
      "85 Great Portland st., 1st floor",
      "London, England, W1W 7LT",
    ],
  },
  {
    title: "Contact us by phone",
    items: [
      <a href="tel:+441514570319">+441514570319</a>,
      "Mon-Fri 9-4pm (UTC -5)",
    ],
  },
  {
    title: "Contact us by email",
    items: [
      <a href="mailto:support@pokerpowerhouse.uk">
        support@pokerpowerhouse.uk
      </a>,
      "Open 24/7",
    ],
  },
  {
    title: "Politics",
    items: [
      <Link to={PATHS.cookie}>Cookie</Link>,
      <Link to={PATHS.privacyPolicy}>Privacy Policy</Link>,
      <Link to={PATHS.termsAndConditions}>Terms and conditions</Link>,
      <Link to={PATHS.refundPolicy}>Refund Policy</Link>,
      <Link to={PATHS.subscriptionPolicy}>Subscription Policy</Link>,
    ],
  },
];
