import { useContext, useEffect, useMemo, useState } from "react";

// hooks
import { useWindowSize } from "../../../hooks/use-window-size/use-window-size";

// context
import { userContext } from "../../../context/user-provider/UserProvider";
import { courseContext } from "../../../context/course-provider/CourseProvider";
import { errorContext } from "../../../context/error/ErrorProvider";

// types
import type {
  CourseType,
  VideoType,
} from "../../../context/course-provider/CourseProvider.types";

export function useMyCoursesFetch(type?: string) {
  const { error } = useContext(errorContext);
  const { userData, getProfile } = useContext(userContext);
  const { coursesData, isCoursesLoading } = useContext(courseContext);

  const [isProfileLoading, setIsProfileLoading] = useState(false);

  const [myBeginnerCoursesData, setMyBeginnerCoursesData] = useState<
    CourseType[]
  >([]);
  const [myProCoursesData, setMyProCoursesData] = useState<CourseType[]>([]);
  const [myVipCoursesData, setMyVipCoursesData] = useState<CourseType[]>([]);

  const [beginnerCoursesData, setBeginnerCoursesData] = useState<CourseType[]>(
    []
  );
  const [vipCoursesData, setVipCoursesData] = useState<CourseType[]>([]);
  const [proCoursesData, setProCoursesData] = useState<CourseType[]>([]);

  const purchasedCourseIds = useMemo(() => {
    if (coursesData && userData) {
      const filteredOrders = userData.orders.filter(
        (order) => order.status === "Completed"
      );
      const orderCourses = filteredOrders.flatMap(
        (order) => order.courses || []
      );

      return new Set(orderCourses.map((course) => course.id));
    }
    return new Set();
  }, [coursesData, userData]);

  const filteredCourses = useMemo(() => {
    if (coursesData && userData) {
      const filteredOrders = userData.orders.filter(
        (order) => order.status === "Completed"
      );
      const orderVideos = filteredOrders.flatMap((order) => order.videos || []);

      const purchasedCourses = coursesData.filter((course) =>
        purchasedCourseIds.has(course.id)
      );

      const additionalCoursesMap = new Map();
      orderVideos.forEach((video) => {
        const { id: courseId } = video.course;
        if (courseId && !purchasedCourseIds.has(courseId)) {
          if (!additionalCoursesMap.has(courseId)) {
            const courseInfo = coursesData.find(
              (course) => course.id === courseId
            );
            if (courseInfo) {
              additionalCoursesMap.set(courseId, {
                ...courseInfo,
                videos: [],
              });
            }
          }
          if (additionalCoursesMap.has(courseId)) {
            additionalCoursesMap.get(courseId).videos.push(video);
          }
        }
      });

      const additionalCourses = Array.from(additionalCoursesMap.values());

      return [...purchasedCourses, ...additionalCourses];
    }

    return [];
  }, [coursesData, userData, purchasedCourseIds]);

  useEffect(() => {
    const beginnerCourses = filteredCourses?.filter(
      (course) => course.category === "beginner"
    );

    if (beginnerCourses) {
      setMyBeginnerCoursesData(beginnerCourses);
    }

    const proCourses = filteredCourses?.filter(
      (course) => course.category === "pro"
    );

    if (proCourses) {
      setMyProCoursesData(proCourses);
    }

    const vipCourses = filteredCourses?.filter(
      (course) => course.category === "vip"
    );

    if (vipCourses) {
      setMyVipCoursesData(vipCourses);
    }
  }, [filteredCourses]);

  useEffect(() => {
    const beginnerCourses = coursesData?.filter(
      (course) => course.category === "beginner"
    );

    if (beginnerCourses) {
      setBeginnerCoursesData(beginnerCourses);
    }

    const vipCourses = coursesData?.filter(
      (course) => course.category === "vip"
    );

    if (vipCourses) {
      setVipCoursesData(vipCourses);
    }
    const proCourses = coursesData?.filter(
      (course) => course.category === "pro"
    );

    if (proCourses) {
      setProCoursesData(proCourses);
    }
  }, [coursesData]);

  const getProfileData = async () => {
    try {
      setIsProfileLoading(true);

      await getProfile();
    } catch (err) {
      error(err);
    } finally {
      setIsProfileLoading(false);
    }
  };

  useEffect(() => {
    if (!type) {
      getProfileData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isMyCoursesLoading = isProfileLoading || isCoursesLoading;

  return {
    isMyCoursesLoading,
    myBeginnerCoursesData,
    myProCoursesData,
    myVipCoursesData,
    filteredCourses,
    beginnerCoursesData,
    proCoursesData,
    vipCoursesData,
  };
}
