import React from "react";

// hooks
import { useMyCoursesFetch } from "./use-my-courses";

// components
import Spinner from "../../../components/spinner/Spinner";
import { MyCourseItem } from "./my-course-item/MyCourseItem";
import { CourseMenuItem } from "../../../components/header/course-menu-item/CourseMenuItem";

// styles
import styles from "./MyCoursesUserScreen.module.scss";

export function MyCoursesUserScreen() {
  const {
    isMyCoursesLoading,
    myBeginnerCoursesData,
    myProCoursesData,
    myVipCoursesData,
    filteredCourses,
    beginnerCoursesData,
    proCoursesData,
    vipCoursesData,
  } = useMyCoursesFetch();

  return (
    <section className={styles.section}>
      {isMyCoursesLoading ? (
        <div className={styles.myCourseLoader}>
          <Spinner size="large" />
        </div>
      ) : (
        <div className={styles.contentWrapper}>
          {filteredCourses.length ? (
            <>
              {myBeginnerCoursesData.length ? (
                <div className={styles.courseTypeWrapper}>
                  <h6>Beginner</h6>

                  {myBeginnerCoursesData.map((course) => (
                    <MyCourseItem course={course} key={course.id} />
                  ))}
                </div>
              ) : null}
              {myProCoursesData.length ? (
                <div className={styles.courseTypeWrapper}>
                  <h6>Pro</h6>

                  {myProCoursesData.map((course) => (
                    <MyCourseItem course={course} key={course.id} />
                  ))}
                </div>
              ) : null}
              {myVipCoursesData.length ? (
                <div className={styles.courseTypeWrapper}>
                  <h6>VIP</h6>

                  {myVipCoursesData.map((course) => (
                    <MyCourseItem course={course} key={course.id} />
                  ))}
                </div>
              ) : null}
            </>
          ) : (
            <div className={styles.emptyCoursesWrapper}>
              <div className={styles.emptyCoursesTitleWrapper}>
                <p>You do not have any purchased courses yet.</p>
                <p>Check out our courses and become a poker master tomorrow!</p>
              </div>

              {beginnerCoursesData.length ? (
                <div className={styles.coursesWrapper}>
                  <p className={styles.courseTitle}>Beginner courses</p>
                  <div className={styles.coursesList}>
                    {beginnerCoursesData.map((course) => (
                      <CourseMenuItem key={course.id} course={course} />
                    ))}
                  </div>
                </div>
              ) : null}

              {proCoursesData.length ? (
                <div className={styles.coursesWrapper}>
                  <p className={styles.courseTitle}>Pro courses</p>
                  <div className={styles.coursesList}>
                    {proCoursesData.map((course) => (
                      <CourseMenuItem key={course.id} course={course} />
                    ))}
                  </div>
                </div>
              ) : null}

              {vipCoursesData.length ? (
                <div className={styles.coursesWrapper}>
                  <p className={styles.courseTitle}>VIP courses</p>
                  <div className={styles.coursesList}>
                    {vipCoursesData.map((course) => (
                      <CourseMenuItem key={course.id} course={course} />
                    ))}
                  </div>
                </div>
              ) : null}
            </div>
          )}
        </div>
      )}
    </section>
  );
}
