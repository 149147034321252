import React, { useCallback, useContext } from "react";
import classnames from "classnames";

// icons
import { CheckIcon } from "../../../../assets/icons/CheckIcon";

// components
import Button from "../../../../components/button/Button";

// context
import { errorContext } from "../../../../context/error/ErrorProvider";
import { cartContext } from "../../../../context/cart-provider/CartProvider";

// type
import type { MembershipTypesType } from "../../../../context/membership-provider/MembershipProvider.types";

// styles
import styles from "./PlansCard.module.scss";

type PlansCardProps = {
  plan: MembershipTypesType;
};

export function PlansCard({ plan }: PlansCardProps) {
  const { success } = useContext(errorContext);
  const { addMembershipToCart } = useContext(cartContext);

  const handleAddBookToCart = useCallback(
    (selectedMembership: MembershipTypesType) => {
      addMembershipToCart(selectedMembership);

      success("The subscription has been successfully added to the cart.");
    },
    [addMembershipToCart]
  );

  const isAnnualPlan = plan.name === "ONE_YEAR";

  const membershipPriceTime = (
    type: "ONE_MONTH" | "ONE_YEAR" | "THREE_YEARS"
  ) => {
    switch (type) {
      case "ONE_MONTH":
        return "Month";

      case "ONE_YEAR":
        return "Year";

      case "THREE_YEARS":
        return "Total";

      default:
        return "";
    }
  };

  const getPlanTitle = (planName: string) => {
    switch (planName) {
      case "ONE_MONTH":
        return "Monthly";
      case "ONE_YEAR":
        return "Annual";
      default:
        return "3 years plan";
    }
  };

  return (
    <div
      className={classnames(
        styles.container,
        isAnnualPlan ? styles.annualContainer : ""
      )}
    >
      <div>
        <p className={styles.title}>{getPlanTitle(plan.name)}</p>
      </div>
      <div className={styles.priceWrapper}>
        <p className={styles.newPrice}>
          € {Number(plan.discountPrice) ? plan.discountPrice : plan.price}
          <span>/{membershipPriceTime(plan.name)}</span>
        </p>
        {Number(plan.discountPrice) ? (
          <div className={styles.diagonalLine}>
            <p className={styles.oldPrice}>€ {plan.price}</p>
          </div>
        ) : null}
      </div>
      <div className={styles.descriptionWrapper}>
        <div className={styles.buttonWrapper}>
          <Button
            width="auto"
            color={isAnnualPlan ? "secondary" : "primary"}
            onClick={() => handleAddBookToCart(plan)}
          >
            Choose plan
          </Button>
        </div>
        <div className={styles.advantages}>
          {plan.options.map((advantage) => (
            <div className={styles.advantage}>
              <div>
                <CheckIcon />
              </div>

              <p>{advantage}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
