import React, { useCallback, useContext, useState } from "react";
import { Link } from "react-router-dom";
import { AxiosError } from "axios";

// helpers
import { getMembershipTitle } from "../../../helpers/get-membership-title";

// components
import Button from "../../button/Button";
import Checkbox from "../../checkbox/Checkbox";

// consts
import { PATHS } from "../../../route/route.controls";

// context
import { orderContext } from "../../../context/order-provider/OrderProvider";
import { paymentContext } from "../../../context/payement-provider/PaymentProvider";
import { sessionContext } from "../../../context/session-provider/SessionProvider";
import { errorContext } from "../../../context/error/ErrorProvider";

// types
import type {
  CourseType,
  VideoType,
} from "../../../context/course-provider/CourseProvider.types";
import type { MembershipTypesType } from "../../../context/membership-provider/MembershipProvider.types";
import type { ErrorResponse } from "../../../context/error/ErrorProvider.types";

// styles
import styles from "./CartModalOrder.module.scss";

type CartModalOrderProps = {
  total: string;
  courses: CourseType[];
  membership: MembershipTypesType | null;
  videos: VideoType[];
  onCloseModal: () => void;
  openLoginModal?: (type: "auth" | "register" | "register_cart") => void;
};

export function CartModalOrder({
  total,
  courses,
  membership,
  videos,
  onCloseModal,
  openLoginModal,
}: CartModalOrderProps) {
  const { error } = useContext(errorContext);
  const { createOrder } = useContext(orderContext);
  const { checkoutPayment } = useContext(paymentContext);
  const { getRoleFromToken } = useContext(sessionContext);

  const role = getRoleFromToken();

  const [isChecked, setIsChecked] = useState(false);

  const handleChangeCheckbox = useCallback(() => {
    setIsChecked((prev) => !prev);
  }, [setIsChecked]);

  const handleCheckout = useCallback(async () => {
    try {
      const coursesIds = courses.map((course) => course.id);

      const videosIds = videos.map((video) => video.id);

      const orderData = await createOrder(
        coursesIds,
        videosIds,
        membership ? membership.id : ""
      );

      if (orderData) {
        await checkoutPayment(orderData.id);
      }
    } catch (e) {
      if (openLoginModal && !role) openLoginModal("register_cart");

      if (e instanceof AxiosError) {
        const customError = e.response?.data as ErrorResponse;
        if (customError.message === "Unauthorized") {
          return error("Please first log in or register.");
        }

        return error(e);
      }

      error(e);
    } finally {
      onCloseModal();
    }
  }, [checkoutPayment, total]);

  return (
    <div className={styles.orderWrapper}>
      <div>
        <h6>Order Summary</h6>
      </div>
      <div className={styles.infoWrapper}>
        {courses.length
          ? courses.map((course) => (
              <div className={styles.info} key={course.id}>
                <p>
                  <span>Course:</span> {course.title}
                </p>
                <p className={styles.price}>
                  € {course.discountPrice ? course.discountPrice : course.price}
                </p>
              </div>
            ))
          : null}

        {membership ? (
          <div className={styles.info}>
            <p>
              <span>Membership</span> {getMembershipTitle(membership.name)}
            </p>{" "}
            <p className={styles.price}>
              €{" "}
              {membership.discountPrice
                ? membership.discountPrice
                : membership.price}
            </p>
          </div>
        ) : null}

        {videos.length
          ? videos.map((video) => (
              <div className={styles.info} key={video.id}>
                <p>
                  <span>Video:</span> {video.title}
                </p>
                <p className={styles.price}>
                  € {video.discountPrice ? video.discountPrice : video.price}
                </p>
              </div>
            ))
          : null}

        <div className={styles.separator} />

        <div className={styles.checkboxWrapper}>
          <Checkbox
            id="terms_and_conditions"
            name="terms"
            value="1"
            checked={isChecked}
            onChange={handleChangeCheckbox}
          />
          <p>
            <span>I have read and agree with</span>{" "}
            <a
              href={PATHS.termsAndConditions}
              target="_blank"
              onClick={onCloseModal}
            >
              Terms of service
            </a>
            <span>,</span>{" "}
            <a
              href={PATHS.privacyPolicy}
              target="_blank"
              onClick={onCloseModal}
            >
              Privacy policy
            </a>{" "}
            <span>and</span>{" "}
            <a
              href={PATHS.subscriptionPolicy}
              target="_blank"
              onClick={onCloseModal}
            >
              Subscription policy
            </a>
          </p>
        </div>

        <div className={styles.total}>
          <p>Total</p>
          <p>€ {total}</p>
        </div>
        <div>
          <Button onClick={handleCheckout} disabled={!isChecked}>
            Secure Checkout
          </Button>
        </div>
      </div>
    </div>
  );
}
