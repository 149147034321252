import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

// components
import App from "./App";

// context
import { ApiProvider } from "./context/api-provider/ApiProvider";
import { UserProvider } from "./context/user-provider/UserProvider";
import { ErrorProvider } from "./context/error/ErrorProvider";
import { CourseProvider } from "./context/course-provider/CourseProvider";
import { MembershipProvider } from "./context/membership-provider/MembershipProvider";
import { CouponProvider } from "./context/coupon-provider/CouponProvider";
import { PaymentProvider } from "./context/payement-provider/PaymentProvider";
import { OrderProvider } from "./context/order-provider/OrderProvider";
import { SessionProvider } from "./context/session-provider/SessionProvider";
import { StorageProvider } from "./context/storage-provider/StorageProvider";
import { CartProvider } from "./context/cart-provider/CartProvider";
import { BlogProvider } from "./context/blog-provider/BlogProvider";

// styles
import "./styles/globals.scss";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <ErrorProvider>
      <SessionProvider>
        <ApiProvider>
          <StorageProvider>
            <UserProvider>
              <CartProvider>
                <BlogProvider>
                  <CourseProvider>
                    <MembershipProvider>
                      <CouponProvider>
                        <PaymentProvider>
                          <OrderProvider>
                            <App />
                          </OrderProvider>
                        </PaymentProvider>
                      </CouponProvider>
                    </MembershipProvider>
                  </CourseProvider>
                </BlogProvider>
              </CartProvider>
            </UserProvider>
          </StorageProvider>
        </ApiProvider>
      </SessionProvider>
    </ErrorProvider>
  </BrowserRouter>
  // </React.StrictMode>
);
