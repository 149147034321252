import { useNavigate } from "react-router-dom";

// components
import Button from "../../../components/button/Button";

// hooks
import { useWindowSize } from "../../../hooks/use-window-size/use-window-size";

// consts
import { PATHS } from "../../../route/route.controls";

// images
import about_bg_src from "../../../assets/images/about-page/about_us_bg.png";
import about_bg_mobile_src from "../../../assets/images/about-page/about_us_bg_mobile.png";
import mission_bg_src from "../../../assets/images/about-page/mission_bg.png";
import mission_bg_mobile_src from "../../../assets/images/about-page/mission_bg_mobile.png";
import training_bg_src from "../../../assets/images/about-page/training_bg.png";
import training_bg_mobile_src from "../../../assets/images/about-page/tranining_bg_mobile.png";
import support_bg_src from "../../../assets/images/about-page/support_bg.png";
import support_bg_mobile_src from "../../../assets/images/about-page/support_bg_mobile.png";
import join_bg_src from "../../../assets/images/about-page/join_bg.png";
import join_bg_mobile_src from "../../../assets/images/about-page/join_bg_mobile.png";

// styles
import styles from "./About.screen.module.scss";

export function AboutScreen() {
  const { width } = useWindowSize();
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <section className={styles.aboutSection}>
        <img
          src={width > 768 ? about_bg_src : about_bg_mobile_src}
          className={styles.aboutImage}
          alt="about"
        />
        <div className={styles.aboutWrapper}>
          <h2 className={styles.aboutTitle}>About Us</h2>
          <p className={styles.aboutDescription}>
            Here at Poker Power House, we are committed to enhancing your poker
            skills. We provide top-notch instructions led by professionals.Which
            are ceentered on strategy, skill-building, and collaborative support
            to facilitate all players, no matter their skills, arrive at the
            level of an expert.If you are a poker beginner or an already
            experienced player, we are here to show you the way to being a
            winner.
          </p>
        </div>
      </section>

      <section className={styles.leftSection}>
        <div className={styles.wrapper}>
          <div className={styles.infoWrap}>
            <h2 className={styles.aboutTitle}>Our Mission</h2>
            <p className={styles.aboutDescription}>
              Our main job at Poker Power House is to change from a player to a
              winner at the tables. Our vision is to make all players be able to
              get to the tables with the best knowledge, skills, and strategies,
              whether they are new enthusiasts or are already advanced but
              willing to reshape their game.
            </p>
          </div>
          <img
            src={width > 768 ? mission_bg_src : mission_bg_mobile_src}
            className={`${styles.imageBg} ${styles.imageRightSide}`}
            alt="mission"
          />
        </div>
      </section>

      <section className={styles.rightSection}>
        <div className={styles.wrapper}>
          <img
            src={width > 768 ? training_bg_src : training_bg_mobile_src}
            className={`${styles.imageBg} ${styles.imageLeftSide}`}
            alt="training"
          />
          <div className={styles.infoWrap}>
            <h2 className={styles.aboutTitle}>
              Expert-Led <br />
              Training
            </h2>
            <p className={styles.aboutDescription}>
              Our online courses are designed by poker experts who have gained
              experience of several decades in the game. They share their
              knowledge using learning materials that are inclusive of deep
              analysis and go far beyond the ground level. Concentrating on the
              strategic and mental factors of poker, will equip you with the
              required knowledge to comprehend the game better.
            </p>
            <div className={styles.buttonWrapper}>
              <Button onClick={() => navigate(PATHS.membership)} width="auto">
                Membership plans
              </Button>
            </div>
          </div>
        </div>
      </section>

      <section className={styles.leftSection}>
        <div className={styles.wrapper}>
          <div className={styles.infoWrap}>
            <h2 className={styles.aboutTitle}>
              Community & <br />
              Support
            </h2>
            <p className={styles.aboutDescription}>
              One of the advantages in playing the game of poker at Poker Power
              House is that it offers a supporting and dynamic learning
              environment. Our like-minded community provides a platform where
              you can tell your tales, ask your queries, and learn from the rest
              of the players. You are our main concern and therefore, we
              acknowledge the need for personalized mentoring, providing way of
              continuous learning and offering individual feedback on your
              progress.
            </p>
            <div className={styles.buttonWrapper}>
              <Button onClick={() => navigate(PATHS.membership)} width="auto">
                Membership plans
              </Button>
            </div>
          </div>
          <img
            src={width > 768 ? support_bg_src : support_bg_mobile_src}
            className={`${styles.imageBg} ${styles.imageRightSide}`}
            alt="support"
          />
        </div>
      </section>

      <section className={styles.rightSection}>
        <div className={styles.wrapper}>
          <img
            src={width > 768 ? join_bg_src : join_bg_mobile_src}
            className={`${styles.imageBg} ${styles.imageLeftSide}`}
            alt="join"
          />
          <div className={styles.infoWrap}>
            <h2 className={styles.aboutTitle}>Join Us</h2>
            <p className={styles.aboutDescription}>
              Take your poker skills to the next level with Poker Power House.
              Our competent trainers and typical exercise types are the ones
              that will surely let you enter the kingdom of poker champions.
              Join Poker Power House and be the winner now!
            </p>
            <div className={styles.buttonWrapper}>
              <Button
                onClick={() => navigate(PATHS.courseOverview)}
                width="auto"
              >
                View Courses
              </Button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
