import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

// icons
import { PlusIcon } from "../../../assets/icons/PlusIcon";

// components
import Button from "../../../components/button/Button";
import Spinner from "../../../components/spinner/Spinner";
import { CourseAdminItem } from "./course-admin-item/CourseAdminItem";
import { CourseAdminModal } from "./course-admin-modal/CourseAdminModal";

// hooks
import { useCoursesAdminFetch } from "./use-courses-admin";
import { useWindowSize } from "../../../hooks/use-window-size/use-window-size";

// styles
import styles from "./CoursesAdminScreen.module.scss";

export function CoursesAdminScreen() {
  const {
    isCoursesLoading,
    beginnerCoursesData,
    vipCoursesData,
    proCoursesData,
  } = useCoursesAdminFetch();

  const { width } = useWindowSize();

  const [isOpenCourseModal, setIsOpenCourseModal] = useState<
    "create" | "edit" | null
  >(null);
  const [courseType, setCourseType] = useState<
    "beginner" | "pro" | "vip" | null
  >(null);

  const handleCreateNewCourse = useCallback(
    (courseType: "beginner" | "pro" | "vip") => {
      setIsOpenCourseModal("create");
      setCourseType(courseType);
    },
    []
  );

  return (
    <section className={styles.coursesSection}>
      {isCoursesLoading ? (
        <Spinner />
      ) : (
        <>
          <div className={styles.courseTypeWrapper}>
            <div className={styles.courseHeader}>
              <h6>Beginner</h6>

              {width >= 768 ? (
                <Button
                  width="auto"
                  icon={<PlusIcon />}
                  iconOrientation="start"
                  onClick={() => handleCreateNewCourse("beginner")}
                >
                  Add Beginner Course
                </Button>
              ) : null}
            </div>

            <div className={styles.coursesList}>
              {beginnerCoursesData.length ? (
                beginnerCoursesData.map((course) => (
                  <CourseAdminItem key={course.id} course={course} />
                ))
              ) : (
                <div>
                  Unfortunately, at the moment, there are no available beginner
                  courses.
                </div>
              )}
            </div>

            {width < 768 ? (
              <Button
                icon={<PlusIcon />}
                iconOrientation="start"
                onClick={() => handleCreateNewCourse("beginner")}
              >
                Add Beginner Course
              </Button>
            ) : null}
          </div>

          <div className={styles.courseTypeWrapper}>
            <div className={styles.courseHeader}>
              <h6>Pro</h6>

              {width >= 768 ? (
                <Button
                  width="auto"
                  icon={<PlusIcon />}
                  iconOrientation="start"
                  onClick={() => handleCreateNewCourse("pro")}
                >
                  Add Pro Course
                </Button>
              ) : null}
            </div>

            <div className={styles.coursesList}>
              {proCoursesData.length ? (
                proCoursesData.map((course) => (
                  <CourseAdminItem key={course.id} course={course} />
                ))
              ) : (
                <div>
                  Unfortunately, at the moment, there are no available PRO
                  courses.
                </div>
              )}
            </div>

            {width < 768 ? (
              <Button
                icon={<PlusIcon />}
                iconOrientation="start"
                onClick={() => handleCreateNewCourse("pro")}
              >
                Add Pro Course
              </Button>
            ) : null}
          </div>

          <div className={styles.courseTypeWrapper}>
            <div className={styles.courseHeader}>
              <h6>Vip</h6>

              {width >= 768 ? (
                <Button
                  width="auto"
                  icon={<PlusIcon />}
                  iconOrientation="start"
                  onClick={() => handleCreateNewCourse("vip")}
                >
                  Add Vip Course
                </Button>
              ) : null}
            </div>

            <div className={styles.coursesList}>
              {vipCoursesData.length ? (
                vipCoursesData.map((course) => (
                  <CourseAdminItem key={course.id} course={course} />
                ))
              ) : (
                <div>
                  Unfortunately, at the moment, there are no available VIP
                  courses.
                </div>
              )}
            </div>

            {width < 768 ? (
              <Button
                icon={<PlusIcon />}
                iconOrientation="start"
                onClick={() => handleCreateNewCourse("vip")}
              >
                Add Vip Course
              </Button>
            ) : null}
          </div>
        </>
      )}

      <CourseAdminModal
        isOpen={isOpenCourseModal}
        onClose={() => setIsOpenCourseModal(null)}
        course={null}
        courseType={courseType}
      />
    </section>
  );
}
