import React, { useState } from "react";
import classnames from "classnames";

// hooks
import { useCoursesAdminForm } from "../use-courses-admin";
import { useWindowSize } from "../../../../hooks/use-window-size/use-window-size";

// icons
import { DeleteIcon } from "../../../../assets/icons/DeleteIcon";
import { EditIcon } from "../../../../assets/icons/EditIcon";
import { ArrowDownIcon } from "../../../../assets/icons/ArrowDownIcon";
import { PlusIcon } from "../../../../assets/icons/PlusIcon";

// components
import Button from "../../../../components/button/Button";
import { ConfirmModal } from "../../../../components/confirm-modal/ConfirmModal";
import { VideoAdminModal } from "../video-admin-modal/VideoAdminModal";
import { VideoItem } from "../../../client/my-courses-user-screen/video-item/VideoItem";
import { CourseAdminModal } from "../course-admin-modal/CourseAdminModal";

// types
import type {
  CourseType,
  VideoType,
} from "../../../../context/course-provider/CourseProvider.types";

// styles
import styles from "./CourseAdminItem.module.scss";

type MyCourseItemProps = {
  course: CourseType;
};

export function CourseAdminItem({ course }: MyCourseItemProps) {
  const { handleRemoveCourse } = useCoursesAdminForm(null, course);

  const { width } = useWindowSize();

  const [isOpenRemovingCourseModal, setIsOpenRemovingCourseModal] =
    useState(false);
  const [isOpenVideoModal, setIsOpenVideoModal] = useState<
    "create" | "edit" | null
  >(null);
  const [isOpenCourseModal, setIsOpenCourseModal] = useState<
    "create" | "edit" | null
  >(null);

  const [isOpenCourseInfo, setIsOpenCourseInfo] = useState(false);

  const [selectedVideo, setSelectedVideo] = useState<VideoType | null>(null);

  const handleOpenEditModal = (
    e: React.MouseEvent<SVGSVGElement, MouseEvent>
  ) => {
    e.stopPropagation();
    setIsOpenCourseModal("edit");
  };

  const handleRemoveSelectedCourse = (
    e: React.MouseEvent<SVGSVGElement, MouseEvent>
  ) => {
    e.stopPropagation();
    setIsOpenRemovingCourseModal(true);
  };

  const handleOpenCreateModal = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    setIsOpenVideoModal("create");
  };

  return (
    <>
      <div
        className={styles.container}
        onClick={
          width < 768 ? () => setIsOpenCourseInfo((prev) => !prev) : undefined
        }
      >
        <div className={styles.courseInfo}>
          <div className={styles.titleWrapper}>
            <div className={styles.titleAndVideos}>
              <p className={styles.title}>{course.title}</p>
              <div className={styles.videos}>
                {course.videos?.length} Videos
              </div>
            </div>

            <div className={styles.iconsWrapper}>
              <div className={styles.icons}>
                <div className={styles.iconButton}>
                  <EditIcon onClick={handleOpenEditModal} />
                </div>
                <div className={styles.iconButton}>
                  <DeleteIcon onClick={handleRemoveSelectedCourse} />
                </div>
              </div>
            </div>
            <div className={styles.buttonWrapper}>
              <Button
                width="auto"
                icon={<PlusIcon />}
                iconOrientation="start"
                variant="outline"
                onClick={handleOpenCreateModal}
                className={styles.videoButton}
              >
                Add Video
              </Button>
            </div>
          </div>

          {width > 768 ? (
            <div
              className={classnames(
                styles.iconWrapper,
                isOpenCourseInfo ? styles.open : ""
              )}
            >
              <ArrowDownIcon
                onClick={() => setIsOpenCourseInfo((prev) => !prev)}
              />
            </div>
          ) : null}
        </div>

        {isOpenCourseInfo ? (
          <div
            className={classnames(
              styles.mainContent,
              isOpenCourseInfo ? styles.open : ""
            )}
          >
            {course.videos?.length ? (
              <div className={styles.videosWrapper}>
                {course.videos
                  .sort(
                    (a, b) =>
                      new Date(a.createdAt).getTime() -
                      new Date(b.createdAt).getTime()
                  )
                  .map((video, index) => (
                    <VideoItem
                      video={video}
                      key={video.id}
                      courseId={course.id}
                      type="admin"
                      videoIndex={index}
                      setSelectedVideo={setSelectedVideo}
                      setIsOpenEditModal={setIsOpenVideoModal}
                    />
                  ))}
              </div>
            ) : (
              <p>There are no videos yet, but you can add a new one.</p>
            )}
          </div>
        ) : null}
      </div>

      <ConfirmModal
        isOpen={isOpenRemovingCourseModal}
        modalTitle="Delete course"
        modalText={"Are you sure to delete this course?"}
        onClose={() => setIsOpenRemovingCourseModal(false)}
        onSubmit={() => handleRemoveCourse(course.id)}
      />

      <CourseAdminModal
        course={course}
        isOpen={isOpenCourseModal}
        onClose={() => setIsOpenCourseModal(null)}
      />

      <VideoAdminModal
        courseId={course.id}
        isOpen={isOpenVideoModal}
        onClose={() => setIsOpenVideoModal(null)}
        video={selectedVideo}
      />
    </>
  );
}
