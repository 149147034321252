import React, { useCallback, useContext, useEffect, useState } from "react";

// context
import { cartContext } from "../../context/cart-provider/CartProvider";

export function useHeaderFetch() {
  const { getCartProducts, cartCount } = useContext(cartContext);

  const [isOpenPreviewCart, setIsOpenPreviewCart] = useState(false);
  const [isOpenCartModal, setIsOpenCartModal] = useState(false);

  const handleOpenCartModal = useCallback(() => {
    setIsOpenCartModal(true);
    setIsOpenPreviewCart(false);
  }, [setIsOpenCartModal]);

  useEffect(() => {
    getCartProducts();
  }, [isOpenPreviewCart, isOpenCartModal, cartCount]);

  return {
    isOpenPreviewCart,
    isOpenCartModal,
    cartCount,
    handleOpenCartModal,
    setIsOpenPreviewCart,
    setIsOpenCartModal,
  };
}
