import React, { useCallback, useContext } from "react";

// hooks
import { useWindowSize } from "../../../../hooks/use-window-size/use-window-size";
import { useBlogAdminForm } from "../use-blog-admin";

// icons
import { DefaultPictureIcon } from "../../../../assets/icons/DefaultPictureIcon";
import { ArrowLeftIcon } from "../../../../assets/icons/ArrowLeftIcon";

// context
import { errorContext } from "../../../../context/error/ErrorProvider";

// components
import Modal from "../../../../components/modal/Modal";
import Button from "../../../../components/button/Button";
import Input from "../../../../components/input/Input";
import Editor from "../../../../components/editor/editor";

// types
import type { BlogType } from "../../../../context/blog-provider/BlogProvider.types";

// styles
import styles from "./BlogAdminModal.module.scss";

type BlogAdminModalProps = {
  blog: BlogType | null;
  isOpen: "create" | "edit" | null;
  onClose: () => void;
};

export function BlogAdminModal({ blog, isOpen, onClose }: BlogAdminModalProps) {
  const { error } = useContext(errorContext);
  const { width } = useWindowSize();

  const {
    blogFormData,
    pictureInputRef,
    isDisabledButton,
    handleChangeBlogAdminData,
    handleChangeEditorData,
    handleCloseModal,
    onSubmit,
  } = useBlogAdminForm(isOpen, blog);

  const closeModal = () => {
    onClose();
    handleCloseModal();
  };

  const isCreatingModal = isOpen === "create";

  const modalHeader = isCreatingModal ? "Add new article" : "Edit article";

  const handleSubmit = useCallback(
    async (e: any) => {
      try {
        await onSubmit(e);
        onClose();
      } catch (e) {
        error(e);
      }
    },
    [onSubmit, onClose, error]
  );

  const onModelChangeCallback = useCallback(
    (text: string) => handleChangeEditorData(text),
    [handleChangeEditorData]
  );

  return (
    <Modal
      isOpen={Boolean(isOpen)}
      onClose={closeModal}
      header={""}
      width={width > 1024 ? "60%" : width > 768 ? "85%" : "100%"}
      size="fullScreen"
      className={styles.modal}
    >
      <form className={styles.form}>
        <div className={styles.mainContent}>
          <div className={styles.titleWrapper}>
            {width < 768 ? (
              <ArrowLeftIcon
                width={24}
                height={24}
                onClick={closeModal}
                className={styles.backArrow}
              />
            ) : null}

            <p className={styles.title}>{modalHeader}</p>
          </div>

          <div className={styles.articleWrapper}>
            <div className={styles.article}>
              <div className={styles.articleInfo}>
                <div className={styles.pictureWrapper}>
                  <input
                    type="file"
                    name="picture"
                    onChange={handleChangeBlogAdminData}
                    ref={pictureInputRef}
                    accept="image/png, image/jpeg"
                    style={{ display: "none" }}
                  />

                  {blogFormData.picture ? (
                    <>
                      <img src={blogFormData.picture} alt="blog screen" />
                      <Button
                        width="auto"
                        type="submit"
                        onClick={(e) => {
                          e.preventDefault();
                          pictureInputRef.current?.click();
                        }}
                        className={styles.pictureButton}
                      >
                        Change
                      </Button>
                    </>
                  ) : (
                    <>
                      <div className={styles.iconWrapper}>
                        <DefaultPictureIcon />
                      </div>

                      <Button
                        width="auto"
                        type="submit"
                        onClick={(e) => {
                          e.preventDefault();
                          pictureInputRef.current?.click();
                        }}
                        className={styles.pictureButton}
                      >
                        Add Picture
                      </Button>
                    </>
                  )}
                </div>

                <div className={styles.inputsWrapper}>
                  <Input
                    variant="admin"
                    label="Article title"
                    name="title"
                    value={blogFormData.title}
                    onChange={handleChangeBlogAdminData}
                  />

                  <Input
                    variant="admin"
                    label="Author"
                    name="author"
                    value={blogFormData.author}
                    onChange={handleChangeBlogAdminData}
                  />
                </div>
              </div>

              <Editor
                model={blogFormData.content}
                onModelChange={onModelChangeCallback}
              />
            </div>
          </div>
        </div>

        <div className={styles.buttonsWrapper}>
          <Button
            width="auto"
            type="submit"
            onClick={handleSubmit}
            disabled={isDisabledButton}
          >
            Save
          </Button>
        </div>
      </form>
    </Modal>
  );
}
