// images
import secondary_arrow_src from "../../../../assets/images/secondary_arrow_background.png";

// hooks
import { useLaterNewsForm } from "./use-later-news";

// components
import { BlogItem } from "../blog-item/BlogItem";
import Input from "../../../../components/input/Input";
import Button from "../../../../components/button/Button";

// types
import type { BlogType } from "../../../../context/blog-provider/BlogProvider.types";

// styles
import styles from "./LaterNews.module.scss";

type LaterNewsProps = {
  laterNewsData: BlogType[];
};

export function LaterNews({ laterNewsData }: LaterNewsProps) {
  const { searchBlogValue, handleChangeHeaderData, searchFilteredArticles } =
    useLaterNewsForm();

  return (
    <section className={styles.newsSection}>
      {laterNewsData && laterNewsData.length ? (
        <img
          src={secondary_arrow_src}
          alt="arrow"
          className={styles.backgroundImage}
        />
      ) : null}

      <div className={styles.contentWrapper}>
        <div className={styles.titleWrapper}>
          <h3 className={styles.title}>Later news</h3>
          <div className={styles.searchWrapper}>
            <Input
              variant="search"
              value={searchBlogValue}
              onChange={handleChangeHeaderData}
              placeholder="Search"
              className={styles.input}
            />
            <Button width="auto" onClick={searchFilteredArticles}>
              Search
            </Button>
          </div>
        </div>

        <div className={styles.mainContent}>
          {laterNewsData && laterNewsData.length ? (
            laterNewsData.map((blog) => <BlogItem key={blog.id} blog={blog} />)
          ) : (
            <h5>
              Unfortunately, your search did not return any results. Please try
              adjusting your search terms and try again.
            </h5>
          )}
        </div>
      </div>
    </section>
  );
}
