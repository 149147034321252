import React from "react";

// consts
import { reviwersData } from "./Reviews.consts";

// components
import CustomReviewItem from "../custom-review-item/CustomReviewItem";
import ReviewItem from "../review-item/ReviewItem";

// styles
import styles from "./Reviews.module.scss";

export function Reviews() {
  return (
    <section className={styles.reviewSection}>
      <div>
        <h3 className={styles.reviewTitle}>Customer testimonials</h3>
      </div>

      <div className={styles.reviewsWrapper}>
        <CustomReviewItem />

        {reviwersData.map((review, index) => (
          <ReviewItem review={review} key={index} />
        ))}
      </div>
    </section>
  );
}
